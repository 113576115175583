import { useCallback, useEffect, useState } from 'react';

export const useHash = () => {
  const [hash, setHash] = useState(window.location.hash);

  const changeHash = useCallback((hash: string) => {
    window.location.hash = hash;
  }, [])

  useEffect(() => {
    const handler = () => {
      setHash(window.location.hash);
    }

    window.addEventListener('hashchange', handler, false);

    return () => window.removeEventListener('hashchange', handler, false);
  }, []);

  return {  hash, setHash: changeHash };
}
