import styled from 'styled-components';
import { FC, RefObject, useCallback, useRef } from 'react';

export const StartView: FC<{ onDownload: (link: string) => void }> = ({ onDownload }) => {

  const ref = useRef() as RefObject<HTMLInputElement>;

  const onClick = useCallback(() => {
    if (ref.current) {
      onDownload(ref.current.value);
      ref.current.value = '';
    }
  }, [onDownload])

  return <>
    <StyledLabel>Link:</StyledLabel>
    <input ref={ref} placeholder="Paste a link to a video stream (m3u8)" />
    <button onClick={onClick}>Convert</button>
  </>;
}

const StyledLabel = styled.div`
  font-size: 12px;
`;
