import { useEffect, useState } from 'react';
import { child, onValue, ref } from 'firebase/database';
import { database } from '../services/firebase';

interface Process<T = unknown> {
  type: string;
  data: T;
}

export const useProcess = <ProcessData = unknown>(processId?: string) => {
  const [process, setProcess] = useState<Process<ProcessData>>();

  useEffect(() => {
    const processesRef = ref(database, 'processes');

    if (processId) {
      return onValue(child(processesRef, processId), (ss) => {
          console.log('set', processId, ss.val());
        if (ss.exists()) {
          setProcess(ss.val());
        }
      });
    }

  }, [processId]);

  return process;
}
