import React, { FC } from 'react';
import styled from 'styled-components';

export const DoneView: FC<{ link: string}> = ({ link }) => {
  return (
    <StyledWrapper>
      <a href={link}>Download video</a>
      <div>Done ✅</div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
