import { createContextProvider } from '../common/createContextProvider';
import { useEffect, useState } from 'react';
import { child, onValue, ref } from 'firebase/database';
import { database } from '../services/firebase';
import { useHash } from '../hooks/useHash';

interface ProcessLink {
  id: string;
  type: string;
  timestamp: number;
}

interface Session {
  sessionId: string,
  processes: ProcessLink[];
  timestamp: number;
}

const DEFAULT_SESSION: Session = { sessionId: '', processes: [], timestamp: 0 };

export const [SessionProvider, useSession] = createContextProvider(() => {
  const { hash } = useHash();
  const [session, setSession] = useState<Session>(DEFAULT_SESSION);

  const sessionId = hash.slice(1);

  useEffect(() => {
    const sessionsRef = ref(database, 'sessions');
    if (hash) {
      return onValue(child(sessionsRef, sessionId), (ss) => {
        if (ss.exists()) {
          setSession(ss.val());
        }
      });
    }
  }, [hash, sessionId]);

  return {
    sessionId,
    processes: session.processes
  };

}, DEFAULT_SESSION);
