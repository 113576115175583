import React, { useCallback, useMemo } from 'react';
import { StartView } from './StartView';
import { useHash } from '../../hooks/useHash';
import { useSession } from '../../providers/session';
import { ProcessView } from './ProcessView';
import styled from 'styled-components';

const { REACT_APP_M3U8_SERVER } = process.env;

export const M3U8 = () => {
  const { setHash } = useHash();
  const { sessionId, processes } = useSession();

  const onDownload = useCallback(async (playlist: string) => {
    const response = await fetch(`${REACT_APP_M3U8_SERVER}/convert`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ playlist, sessionId })
    });
    const { sessionId: newSessionId } = await response.json();

    if (newSessionId !== sessionId) {
      setHash(newSessionId);
    }
  }, [setHash, sessionId])

  const processViews = useMemo(() =>
    processes
      .filter(({ type }) => type === 'm3u8')
      .reverse()
      .map(({ id }) => {
        return <ProcessView key={id} processId={id} />
      })
  , [processes]);

  return <StyledWrapper>
    <StartView onDownload={onDownload} />
    <StyledProcesses>
      {processViews}
    </StyledProcesses>
  </StyledWrapper>
};

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledProcesses = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 240px;
  overflow: scroll;
  gap: 8px;
`;
