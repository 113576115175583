import React, { useCallback } from 'react';
import styled from 'styled-components';

export const Links = () => {
  const navigate = useCallback((url: string) => {
    window.open(url);
  }, [])

  return (
    <StyledWrapper>
      <button onClick={ () => navigate("/projects/engine")}>3D Terrain Editor</button>
      <button onClick={ () => navigate("/examples/Animator")}>Animator (Library)</button>
      <button onClick={ () => navigate("/ml")}>Symbol Neural Network</button>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  z-index: 1;
`;
