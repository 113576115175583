import React, { FC } from 'react';
import { useProcess } from '../../hooks/useProcess';
import { DoneView } from './DoneView';
import { ProgressView } from './ProgressView';
import { M3U8ProcessData } from './types';
import styled from 'styled-components';


export const ProcessView: FC<{ processId?: string }> = ({ processId }) => {
  const process = useProcess<M3U8ProcessData>(processId);

  const { data } = process || {};

  const isQueue = !process || data?.process === 'queue';
  const showProgress = !isQueue && Boolean(data) && !data?.done;
  const showFinalLink = !isQueue && data?.done && data?.link;

  return <StyledDownloadWrapper>
    {isQueue && 'The task in a queue...'}
    {showProgress && <ProgressView state={data!}/>}
    {showFinalLink && <DoneView link={data.link!} />}
  </StyledDownloadWrapper>
}

const StyledDownloadWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 8px;
  border-radius: 4px;
  color: white;
`;
