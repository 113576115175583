import React, { createContext, FC, PropsWithChildren, useContext } from 'react';

export const createContextProvider = <T extends (...args: any[]) => any, P extends {} = {}>(
  hook: T,
  defaultValue: ReturnType<T>,
): [FC<PropsWithChildren<{ params?: P }>>, () => ReturnType<T>] => {
  const context = createContext(defaultValue);

  const ProviderComponent: FC<PropsWithChildren<{ params?: P }>> = ({
    children,
    params = {},
  }): React.ReactElement => <context.Provider value={hook(params)}>{children}</context.Provider>;

  const useContextHook: () => ReturnType<T> = () => useContext<ReturnType<T>>(context);

  return [ProviderComponent, useContextHook];
};
