import { FC } from 'react';
import styled from 'styled-components';
import { M3U8ProcessData } from './types';

export const ProgressView: FC<{ state: M3U8ProcessData }> = ({ state }) => {
  const { downloading, rendering} = state;
  const steps = [downloading, rendering];
  const totalProgress = Math.round( steps.reduce((acc, step) => acc + step, 0) / steps.length);

  return (
    <>
      <StyledTitle>Processing...<div>{totalProgress}%</div></StyledTitle>
      <StyledWrapper>
        <StyledProcess>Download<StyledProgress value={downloading} color="orange" /></StyledProcess>
        <StyledProcess>Render<StyledProgress value={rendering} color="green" /></StyledProcess>
      </StyledWrapper>
    </>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: stretch;
  text-align: center;
`;

const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledProcess = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledProgress = styled.div<{ value: number; color: string }>`
  position: relative;
  height: 4px;
  
  &::after {
    background-color: ${({ color }) => color};
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${({ value }) => value}%;
  }
`;
